<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>
        {{ this.$store.state.collectionName }}
      </h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="collection" />
  </div>
</template>
<script>

export default {
  name: 'Collection',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Collection',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
        name: 'description',
        content: 'Be the first to shop Bengalina’s new collection! Find the latest tops, dresses, bottoms, and co-ords in Western and traditional fashion trends.',
        },
        {
          property: 'og:title',
          content: 'New Fashion Collection - Tops, Dresses, & More | Bengalina',
        },
        {
          property: 'og:description',
          content: 'Explore the fresh arrivals at Bengalina with the latest trends in tops, dresses, bottoms, and co-ords, blending Western and traditional styles.',
        },
        {
          name: 'keywords',
          content: 'new collection Bengalina, fall collection, winter collection, eid collection, summer collection, spring collection, latest fashion Dhaka, new arrivals Bangladesh, trendy clothing Dhaka, women’s fashion Bangladesh, tops, dresses, bottoms, co-ords',
        },
      ]
    }
  },
  computed: {
    collection() {
      return this.$store.state.newCollection;
    },
  },
};
</script>
